import color from './color';
import typo from './typo';

const theme = {
  color: {
    ...color,
  },
  typo: {
    ...typo,
  },
};

type Theme = typeof theme;

export default theme;
export type { Theme };
