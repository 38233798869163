const color = {
  BW1: '#32363A',
  BW2: '#646A70',
  BW3: '#AEB5BA',
  BW4: '#DEE2E6',
  BW5: '#FCFCFC',
  BW6: '#FFFFFF',
  BL1: '#5AA6FF',
  BL2: '#57C7EC',
  BL3: '#4A6F9A',
  BL4: '#F4F9FF',
  PPL: '#8124FF',
  RD1: '#F36363',
  RD2: '#C63A3A',
  TRANSPARENT: 'transparent',
};
export default color;
