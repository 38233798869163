import React from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import theme from 'styles/theme';
import GlobalStyle from 'styles/global';
import { ConfigProvider } from 'antd';
import koKR from 'antd/lib/locale/ko_KR'; // 한국어 설정 가져오기
import {
  Login,
  User,
  UserDetail,
  UserTier,
  UserTierDetail,
  UserSocial,
  UserSocialDetail,
  UserPointAndCashback,
  UserPointAndCashbackDetail,
  SupportNotice,
  SupportFaq,
  SupportCampaignGuide,
  SupportEvent,
  SupportCampaignGuideDetail,
  SupportEventDetail,
  SupportFaqDetail,
  SupportNoticeDetail,
  CampaignList,
  // CampaignListDetail,
  CampaignCoupon,
  CampaignCouponDetail,
  CampaignGiveup,
  CampaignGiveupDetail,
  MissionList,
  MissionApproval,
  MissionApprovalDetail,
  MissionDetail,
  MissionByCampaign,
  MissionSocial,
  MissionSocialDetail,
  MissionByCampaignDetail,
  SettlementPoint,
  SettlementPointDetail,
  SettlementCashback,
  SettlementCashbackDetail,
  ServiceBanner,
  ServiceBannerDetail,
  ServicePopup,
  ServicePopupDetail,
  ServicePush,
  ServicePushDetail,
  ServiceReferer,
  ServiceRefererDetail,
  UserPenalty,
  UserPenaltyDetail,
  UserTierCreate,
  SupportNoticeCreate,
  SupportFaqCreate,
  SupportEventCreate,
  CampaignCouponCreate,
  CampaignSelect,
  CampaignSelectDetail,
  CampaignLive,
  CampaignLiveDetail,
  MissionApprovalList,
  ProductList,
  ClientList,
  ClientDetail,
  ProductDetail,
  CampaignCreateShopping,
  CampaignCreateSNS,
  CampaignCreateSearch,
  CampaignCreateEtc,
  CampaignListDetailNew,
  MissionCampaignShopping,
  MissionCampaignSearch,
  MissionCampaignSns,
  MissionCampaignEtc,
  TestPage,
  CampaignMissionProcess,
  CampaginJoinUserList,
  MissionCampaignDetail,
  UserBlackList,
  CampaignHotList,
} from 'pages';
import { QueryClient, QueryClientProvider } from 'react-query';
import PrivateRoute from 'route';
import Container from './components/Container';
import { motion } from 'framer-motion';
import { HelmetProvider } from 'react-helmet-async';

const StatefulApp = () => (
  <BrowserRouter>
    <GlobalStyle />
    <Routes>
      <Route index path="/" element={<Login />} />
      <Route path="/test" element={<TestPage />} />

      <Route path="/user" element={<PrivateRoute />}>
        <Route index path="list" element={<User />} />
        <Route index path="list/detail" element={<UserDetail />} />
        <Route index path="tier" element={<UserTier />} />
        <Route index path="tier/detail" element={<UserTierDetail />} />
        <Route index path="tier/create" element={<UserTierCreate />} />
        <Route index path="social" element={<UserSocial />} />
        <Route index path="social/detail" element={<UserSocialDetail />} />
        <Route index path="point-and-cashback" element={<UserPointAndCashback />} />
        <Route index path="point-and-cashback/detail" element={<UserPointAndCashbackDetail />} />
        <Route index path="penalty" element={<UserPenalty />} />
        <Route index path="penalty/detail" element={<UserPenaltyDetail />} />
        <Route index path="blacklist" element={<UserBlackList />} />
      </Route>

      <Route path="/support" element={<PrivateRoute />}>
        <Route index path="notice" element={<SupportNotice />} />
        <Route index path="notice/detail" element={<SupportNoticeDetail />} />
        <Route index path="notice/create" element={<SupportNoticeCreate />} />
        <Route index path="faq" element={<SupportFaq />} />
        <Route index path="faq/create" element={<SupportFaqCreate />} />
        <Route index path="faq/detail" element={<SupportFaqDetail />} />
        <Route index path="event" element={<SupportEvent />} />
        <Route index path="event/create" element={<SupportEventCreate />} />
        <Route index path="event/detail" element={<SupportEventDetail />} />
        <Route index path="campaign-guide" element={<SupportCampaignGuide />} />
        <Route index path="campaign-guide/detail" element={<SupportCampaignGuideDetail />} />
      </Route>

      <Route path="/client" element={<PrivateRoute />}>
        <Route index path="list" element={<ClientList />} />
        <Route index path="list/detail" element={<ClientDetail />} />
      </Route>

      <Route path="/product" element={<PrivateRoute />}>
        <Route index path="list" element={<ProductList />} />
        <Route index path="list/detail" element={<ProductDetail />} />
      </Route>

      <Route path="/campaign" element={<PrivateRoute />}>
        <Route index path="list" element={<CampaignList />} />
        <Route index path="hot-list" element={<CampaignHotList />} />
        <Route index path="create/shopping" element={<CampaignCreateShopping />} />
        <Route index path="create/sns" element={<CampaignCreateSNS />} />
        <Route index path="create/search" element={<CampaignCreateSearch />} />
        <Route index path="create/etc" element={<CampaignCreateEtc />} />

        {/* <Route index path="list/detail" element={<CampaignListDetail />} /> */}
        <Route index path="list/detail-new" element={<CampaignListDetailNew />} />
        <Route index path="list/detail-new/missionprogress" element={<CampaignMissionProcess />} />
        <Route index path="list/detail-new/users" element={<CampaginJoinUserList />} />

        <Route index path="coupon" element={<CampaignCoupon />} />
        <Route index path="coupon/detail" element={<CampaignCouponDetail />} />
        <Route index path="coupon/create" element={<CampaignCouponCreate />} />
        <Route index path="giveup" element={<CampaignGiveup />} />
        <Route index path="giveup/detail" element={<CampaignGiveupDetail />} />
        <Route index path="select" element={<CampaignSelect />} />
        <Route index path="select/detail" element={<CampaignSelectDetail />} />
        <Route index path="live" element={<CampaignLive />} />
        <Route index path="live/detail" element={<CampaignLiveDetail />} />
      </Route>

      <Route path="/mission" element={<PrivateRoute />}>
        <Route index path="list" element={<MissionList />} />
        <Route index path="list/detail" element={<MissionDetail />} />
        <Route index path="approval" element={<MissionApproval />} />
        <Route index path="approval/list" element={<MissionApprovalList />} />
        <Route index path="approval/detail" element={<MissionApprovalDetail />} />
        <Route index path="by-campaign" element={<MissionByCampaign />} />
        <Route index path="by-campaign/detail" element={<MissionByCampaignDetail />} />
        <Route index path="social" element={<MissionSocial />} />
        <Route index path="social/detail" element={<MissionSocialDetail />} />
        <Route index path="campaign/shopping" element={<MissionCampaignShopping />} />
        <Route index path="campaign/search" element={<MissionCampaignSearch />} />
        <Route index path="campaign/sns" element={<MissionCampaignSns />} />
        <Route index path="campaign/etc" element={<MissionCampaignEtc />} />
        <Route index path="campaign/detail" element={<MissionCampaignDetail />} />
      </Route>

      <Route path="/settlement" element={<PrivateRoute />}>
        <Route index path="point" element={<SettlementPoint />} />
        <Route index path="point/detail" element={<SettlementPointDetail />} />
        <Route index path="cashback" element={<SettlementCashback />} />
        <Route index path="cashback/detail" element={<SettlementCashbackDetail />} />
      </Route>

      <Route path="/service" element={<PrivateRoute />}>
        <Route index path="banner" element={<ServiceBanner />} />
        <Route index path="banner/detail" element={<ServiceBannerDetail />} />
        <Route index path="popup" element={<ServicePopup />} />
        <Route index path="popup/detail" element={<ServicePopupDetail />} />
        <Route index path="push" element={<ServicePush />} />
        <Route index path="push/detail" element={<ServicePushDetail />} />
        <Route index path="referer" element={<ServiceReferer />} />
        <Route index path="referer/detail" element={<ServiceRefererDetail />} />
      </Route>

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  </BrowserRouter>
);

const queryClient = new QueryClient();

const noLayoutPageAnimation = {
  initial: {
    opacity: 0,
    x: 10,
  },
  animate: {
    opacity: 1,
    x: 0,
  },
};

function App() {
  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <ConfigProvider locale={koKR}>
            <Container>
              <motion.div {...noLayoutPageAnimation}>
                <StatefulApp />
              </motion.div>
            </Container>
          </ConfigProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </HelmetProvider>
  );
}

export default App;
