import styled from "styled-components";

const ScrollWrapper = styled.div`
  width: 100%;
  height: 100%;
`
const ContainerWrapper = styled.div`
  min-width: 1500px;
`;

interface ContainerProps {
    children: any
}
const Container = ({children}: ContainerProps) => {
    return <ScrollWrapper>
        <ContainerWrapper>
            {children}
        </ContainerWrapper>
    </ScrollWrapper>
}

export default Container;
