import { css } from 'styled-components';

const typo = {
  '32B-700': css`
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
  `,
  '24B-700': css`
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
  `,
  '20B-700': css`
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
  `,
  '16B-700': css`
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
  `,
  '14B-700': css`
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
  `,
  '12B-700': css`
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
  `,
  '16SB-600': css`
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
  `,
  '14SB-600': css`
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
  `,
  '12SB-600': css`
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
  `,
  '16M-500': css`
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
  `,
  '14M-500': css`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
  `,
  '12M-500': css`
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
  `,
  '16R-400': css`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
  `,
  '14R-400': css`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  `,
  '12R-400': css`
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
  `,
};

export default typo;
