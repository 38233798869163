import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

const GlobalStyle = createGlobalStyle`
  @import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.6/dist/web/static/pretendard.css");
  html {
    width: 100%;
    height: 100%;
  }

  
  *,
  *::before,
  *::after {
    font-family: "Pretendard Variable", sans-serif !important;
    box-sizing: border-box !important;
  }

  body {
    margin: 0 !important;
    padding: 0 !important;
    width: 100%;
    height: 100%;
  }

  #root {
    width: 100%;
    height: 100%;
  }

  button {
    cursor: pointer;
  }

  #storybook-root {
    padding: 64px;
  }
  .loading-modal{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .5);
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s;
    .loader {
      width: 48px;
      height: 48px;
      border: 5px solid #FFF;
      border-bottom-color: #FF3D00;
      border-radius: 50%;
      display: inline-block;
      box-sizing: border-box;
      animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
    ${reset}
`;

export default GlobalStyle;
