import { create } from 'zustand';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';

type AuthStoreActions = {
  setAccessToken: (token: string) => void;
  clearToken: () => void;
};

type AuthStore = {
  accessToken: string;
  actions: AuthStoreActions;
};

export const useAuthStore = create<AuthStore>()(
  devtools(
    persist(
      set => ({
        accessToken: '',
        actions: {
          setAccessToken: (token: string) => set({ accessToken: token }),
          clearToken: () => set({ accessToken: '' }),
        },
      }),
      {
        name: 'auth-storage',
        storage: createJSONStorage(() => sessionStorage),
        partialize: ({ actions, ...state }) => ({ ...state }),
      }
    )
  )
);

export const useAccessToken = () => useAuthStore(({ accessToken }) => accessToken);
export const useAuthActions = () => useAuthStore(({ actions }) => ({ ...actions }));
