export const parseJwt = (token?: string) => {
  try {
    if (!token || token.length === 0) {
      return null;
    }

    const base64Url = token.split('.')[1];
    return JSON.parse(atob(base64Url));
  } catch (e) {
    return null;
  }
};
