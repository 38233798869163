import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAccessToken, useAuthActions } from 'hooks/store/auth';
import { parseJwt } from 'utils/jwt';

const PrivateRoute = () => {
  const [isExpired, setIsExpired] = useState<boolean | null>(null);
  const accessToken = useAccessToken();
  const { clearToken } = useAuthActions();

  useEffect(() => {
    const jwt = parseJwt(accessToken);

    if (jwt === null || jwt.exp < Date.now() / 1000) {
      clearToken();
      setIsExpired(true);
      return;
    }

    setIsExpired(false);
  }, [accessToken, clearToken]);

  if (isExpired === null) {
    return null;
  }

  return isExpired ? <Navigate to="/" /> : <Outlet />;
};

export default PrivateRoute;
