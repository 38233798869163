import loadable from '@loadable/component';

export const Login = loadable(() => import('./login'));
export const User = loadable(() => import('./user/list'));
export const UserDetail = loadable(() => import('./user/detail'));
export const UserTier = loadable(() => import('./user/tier'));
export const UserTierDetail = loadable(() => import('./user/tier-detail'));
export const UserTierCreate = loadable(() => import('./user/tier-create'));
export const UserSocial = loadable(() => import('./user/social'));
export const UserSocialDetail = loadable(() => import('./user/social-detail'));
export const UserPointAndCashback = loadable(() => import('./user/point-and-cashback'));
export const UserPointAndCashbackDetail = loadable(() => import('./user/point-and-cashback-detail'));
export const UserPenalty = loadable(() => import('./user/penalty'));
export const UserPenaltyDetail = loadable(() => import('./user/penalty-detail'));
export const UserBlackList = loadable(() => import('./user/blacklist'));

export const SupportNotice = loadable(() => import('./support/notice'));
export const SupportNoticeCreate = loadable(() => import('./support/notice-create'));
export const SupportNoticeDetail = loadable(() => import('./support/notice-detail'));
export const SupportFaq = loadable(() => import('./support/faq'));
export const SupportFaqCreate = loadable(() => import('./support/faq-create'));
export const SupportFaqDetail = loadable(() => import('./support/faq-detail'));
export const SupportEvent = loadable(() => import('./support/event'));
export const SupportEventCreate = loadable(() => import('./support/event-create'));
export const SupportEventDetail = loadable(() => import('./support/event-detail'));
export const SupportCampaignGuide = loadable(() => import('./support/campaign-guide'));
export const SupportCampaignGuideDetail = loadable(() => import('./support/campaign-guide-detail'));

export const ClientList = loadable(() => import('./client/list'));
export const ClientDetail = loadable(() => import('./client/detail'));

export const ProductList = loadable(() => import('./product/list'));
export const ProductDetail = loadable(() => import('./product/detail'));

export const CampaignList = loadable(() => import('./campaign/list'));
export const CampaignHotList = loadable(() => import('./campaign/hot-list'));
export const CampaignCreateShopping = loadable(() => import('./campaign/create/shopping'));
export const CampaignCreateSNS = loadable(() => import('./campaign/create/sns'));
export const CampaignCreateSearch = loadable(() => import('./campaign/create/search'));
export const CampaignCreateEtc = loadable(() => import('./campaign/create/etc'));
// export const CampaignListDetail = loadable(() => import('./campaign/detail'));
export const CampaignListDetailNew = loadable(() => import('./campaign/detail-new'));
export const CampaignMissionProcess = loadable(() => import('./campaign/detail-new/MissionProgress'));
export const CampaginJoinUserList = loadable(() => import('./campaign/detail-new/JoinUserList'));
export const CampaignCoupon = loadable(() => import('./campaign/coupon'));
export const CampaignCouponDetail = loadable(() => import('./campaign/coupon-detail'));
export const CampaignCouponCreate = loadable(() => import('./campaign/coupon-create'));
export const CampaignGiveup = loadable(() => import('./campaign/giveup'));
export const CampaignGiveupDetail = loadable(() => import('./campaign/giveup-detail'));
export const CampaignSelect = loadable(() => import('./campaign/select'));
export const CampaignSelectDetail = loadable(() => import('./campaign/select-detail'));
export const CampaignLive = loadable(() => import('./campaign/live'));
export const CampaignLiveDetail = loadable(() => import('./campaign/live-detail'));

export const MissionList = loadable(() => import('./mission/list'));
export const MissionDetail = loadable(() => import('./mission/detail'));
export const MissionApproval = loadable(() => import('./mission/approval'));
export const MissionApprovalList = loadable(() => import('./mission/approval-list'));
export const MissionApprovalDetail = loadable(() => import('./mission/approval-detail'));
export const MissionByCampaign = loadable(() => import('./mission/by-campaign'));
export const MissionByCampaignDetail = loadable(() => import('./mission/by-campaign-detail'));
export const MissionSocial = loadable(() => import('./mission/social'));
export const MissionSocialDetail = loadable(() => import('./mission/social-detail'));
export const MissionCampaignShopping = loadable(() => import('./mission/campaign/shopping'));

export const MissionCampaignSearch = loadable(() => import('./mission/campaign/search'));
export const MissionCampaignSns = loadable(() => import('./mission/campaign/sns'));
export const MissionCampaignEtc = loadable(() => import('./mission/campaign/etc'));

export const MissionCampaignDetail = loadable(()=> import('./mission/campaign-detail'));

export const SettlementPoint = loadable(() => import('./settlement/point'));
export const SettlementPointDetail = loadable(() => import('./settlement/point-detail'));
export const SettlementCashback = loadable(() => import('./settlement/cashback'));
export const SettlementCashbackDetail = loadable(() => import('./settlement/cashback-detail'));

export const ServiceBanner = loadable(() => import('./service/banner'));
export const ServiceBannerDetail = loadable(() => import('./service/banner-detail'));
export const ServicePopup = loadable(() => import('./service/popup'));
export const ServicePopupDetail = loadable(() => import('./service/popup-detail'));
export const ServicePush = loadable(() => import('./service/push'));
export const ServicePushDetail = loadable(() => import('./service/push-detail'));
export const ServiceReferer = loadable(() => import('./service/referer'));
export const ServiceRefererDetail = loadable(() => import('./service/referer-detail'));

export const TestPage = loadable(() => import('./test'));
